import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { IToast, ToastService } from '@cf/core';
import { ButtonModule } from '@cf/shared/ui/button';
import { IconModule } from '@cf/shared/ui/icons';
import { NgbToast, NgbToastHeader } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ENotificationAction } from '../notification-action';

@Component({
  selector: 'cf-toast-container',
  standalone: true,
  imports: [
    CommonModule,
    NgbToast,
    NgbToastHeader,
    IconModule,
    ButtonModule,
    TranslateModule,
  ],
  template: `
    <ng-container *ngIf="toasts$ | async as toasts">
      <ngb-toast
        *ngFor="let toast of toasts"
        [class]="toast.classname"
        [autohide]="toast.autoHide"
        [delay]="toast.delay || 5000"
        (hidden)="remove(toast)"
        [header]="toast.message"
      >
        <ng-template ngbToastHeader>
          <div class="w-100">
            <header
              *ngIf="toast.classname === 'warning' && toast.title"
              class="mb-2"
            >
              {{ toast.title }}
            </header>
            <div class="me-auto d-flex align-items-baseline">
              @if (toast.classname === 'info') {
                <cf-icon
                  icon="info_circle"
                  color="blue-50"
                  class="me-2"
                ></cf-icon>
              } @else {
                <cf-icon
                  *ngIf="toast.classname !== 'warning'"
                  [icon]="
                    toast.classname === 'success'
                      ? 'check_circle'
                      : 'cross_circle'
                  "
                  class="me-2"
                ></cf-icon>
              }

              <div>
                <div>
                  {{ toast.message | translate }}
                </div>
                @if (toast.actionText) {
                  <a
                    cf-link
                    size="sm"
                    class="mt-2"
                    (click)="action($any(toast))"
                  >
                    {{ toast.actionText | translate }}
                  </a>
                }
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-toast>
    </ng-container>
  `,
  styleUrls: ['./toast-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ToastContainerComponent {
  toasts$ = this.toastService.toasts$.asObservable();

  constructor(private readonly toastService: ToastService) {}

  remove(toast: IToast) {
    this.toastService.remove(toast);
  }

  action(toast: IToast) {
    switch (toast.actionText) {
      case ENotificationAction.RELOAD:
        document.location.reload();
        break;
      case ENotificationAction.SELECT_ANOTHER_TOOL:
        document
          .querySelector('cf-location-form')
          ?.scrollIntoView({ behavior: 'smooth' });
        this.remove(toast);
        // element?.scrollIntoView({ behavior: 'smooth' });
        break;
    }
  }
}
