import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { injectEnvironment } from '@cf/core';
import { ButtonModule } from '@cf/shared/ui/button';
import { TypographyModule } from '@cf/shared/ui/typography';
import { LogosModule } from '../logos';

@Component({
  selector: 'cf-footer',
  standalone: true,
  imports: [
    CommonModule,
    LogosModule,
    RouterLink,
    ButtonModule,
    TypographyModule,
  ],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent {
  public year = new Date().getFullYear();

  protected environment = injectEnvironment();
}
