import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ResponsiveService } from '@cf/core';
import { AuthService } from '@cf/temp/services';
import { Observable } from 'rxjs';

import { AccountQuery, AccountService } from '@cf/temp/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs/operators';
import { ModalConnectCalendarComponent } from '../../../../../libs/auth';

@Component({
  selector: 'cf-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  @Output() toggleSidenav = new EventEmitter();

  isTouch$: Observable<boolean> = this.responsiveService.isTouch$;

  showCalendarAlert$ = this.accountQuery.showCalendarAlert$;

  showCalendarAlert!: boolean;

  constructor(
    public readonly authService: AuthService,
    public readonly accountService: AccountService,
    private readonly accountQuery: AccountQuery,
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: NgbModal,
    private readonly router: Router,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((res: any) => {
        const url = res.url as string;
        this.showCalendarAlert =
          url.includes('dashboard') || url.includes('meeting/group/view');
        this.cd.detectChanges();
      });
  }

  openWindow() {
    const modal = this.modalService.open(ModalConnectCalendarComponent, {
      size: 'medium',
    });

    modal.componentInstance.isNewUser = false;
  }
}
