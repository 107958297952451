import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { injectEnvironment } from '@cf/core';
import { AuthService } from '@cf/temp/services';
import { DateTime } from 'luxon';

@Component({
  selector: 'cf-google-one-tap',
  template: '',
  styleUrls: ['./google-one-tap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleOneTapComponent implements AfterViewInit {
  private environment = injectEnvironment();
  constructor(private readonly authService: AuthService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.authService.isAuthorized$.subscribe((res) => {
        if (res) {
          google.accounts.id.cancel();
        } else {
          this.initOneTap();
        }
      });
    }, 500);
  }

  handleGgOneTap(resp: any) {
    this.login(resp.credential);
  }

  login(token: string): void {
    this.authService
      .loginByGoogleToken(
        {
          token,
          timezone: DateTime.local().zoneName,
        },
        'google-one-tap',
      )
      .subscribe();
  }

  private initOneTap() {
    google.accounts.id.initialize({
      client_id: this.environment.googleClientId,
      callback: (response: any) => this.handleGgOneTap(response),
      cancel_on_tap_outside: false,
    });

    google.accounts.id.prompt((notification: any) => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        document.cookie =
          'g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
        window.google.accounts.id.prompt();
      }
    });
  }
}
