import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  RouterLink,
  RouterLinkActive,
  RouterLinkWithHref,
} from '@angular/router';
import { IconModule } from '@cf/shared/ui/icons';
import { FormsCfModule } from '../../widgets/forms';
import { LogosModule } from '../../widgets/logos';
import { HeaderUserFlowComponent } from './components/header-user-flow/header-user-flow.component';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from '@cf/shared/ui/button';
import { TypographyModule } from '@cf/shared/ui/typography';
import { DropdownModule } from '../../widgets/dropdown/dropdown.module';
import { DebounceClickDirective } from '../../widgets/forms/directives/debounce-click.directive';
import { CreateButtonComponent } from './components/create-button/create-button.component';
import { GoogleOneTapComponent } from './components/google-one-tap/google-one-tap.component';
import { HeaderComponent } from './components/header/header.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { UserNoticeComponent } from './components/user-notice/user-notice.component';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderUserFlowComponent,
    GoogleOneTapComponent,
    CreateButtonComponent,
    SidenavComponent,
    UserNoticeComponent,
  ],
  exports: [HeaderComponent, SidenavComponent, HeaderUserFlowComponent],
  imports: [
    CommonModule,
    RouterLinkWithHref,
    RouterLink,
    ButtonModule,
    LogosModule,
    IconModule,
    RouterLinkActive,
    FormsCfModule,
    DropdownModule,
    TypographyModule,
    TranslateModule,
    DebounceClickDirective,
  ],
})
export class LayoutModule {}
