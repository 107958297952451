<div
  class="overlay"
  (click)="toggleSidenav.emit()"
  *ngIf="isMobile$ | async"
></div>

<div class="wrapper">
  <ng-container *ngIf="(isTouch$ | async) === false">
    <cf-icon
      *ngIf="!pinned"
      icon="pin"
      class="pin"
      (click)="pinNavigation()"
    ></cf-icon>
    <cf-icon
      size="sm"
      *ngIf="pinned"
      icon="double_arrow_back"
      class="pin"
      (click)="immediatelyClose()"
    ></cf-icon>
  </ng-container>

  <cf-icon
    *ngIf="isMobile$ | async"
    icon="close"
    class="pin"
    color="grey-70"
    (click)="toggleSidenav.emit()"
  ></cf-icon>

  <div
    cf-main-logo
    size="sm"
    logoHeight="32"
    class="ms-3 mt-xl-3 logo-sm"
    routerLink="/dashboard"
    [queryParamsHandling]="getQueryParamsHandling('/dashboard')"
    (click)="closeSidenav()"
  ></div>
  <div
    cf-main-logo
    size="lg"
    logoHeight="32"
    class="ms-3 mt-xl-3 logo-lg"
    (click)="closeSidenav()"
    routerLink="/dashboard"
    [queryParamsHandling]="getQueryParamsHandling('/dashboard')"
  ></div>
  <cf-create-button
    *ngIf="isAuthorized"
    class="mt-4 ms-12"
    (emitNavigate)="closeSidenav()"
  ></cf-create-button>

  <div class="menu-items mt-5 mb-5" *ngIf="isAuthorized">
    <a
      cf-link
      size="sm"
      color="grey-80"
      routerLink="/dashboard"
      routerLinkActive="active"
      [queryParamsHandling]="getQueryParamsHandling('/dashboard')"
      (click)="closeSidenav()"
    >
      <cf-icon icon="house" color="grey-80"></cf-icon>
      <span class="item">
        {{ 'dashboard' | translate }}
      </span>
    </a>
    <a
      cf-link
      size="sm"
      color="grey-80"
      class="mt-3"
      routerLink="/events/list"
      routerLinkActive="active"
      [queryParamsHandling]="getQueryParamsHandling('/events/list')"
      (click)="closeSidenav()"
    >
      <cf-icon icon="calendar" color="grey-80"></cf-icon>
      <span class="item">
        {{ 'events.title' | translate }}
      </span>
    </a>
    <a
      cf-link
      size="sm"
      color="grey-80"
      class="mt-3"
      routerLink="/group-dashboard"
      routerLinkActive="active"
      [queryParamsHandling]="getQueryParamsHandling('/group-dashboard')"
      (click)="closeSidenav()"
    >
      <cf-icon icon="documents" color="grey-80"></cf-icon>
      <span class="item">
        {{ 'groups' | translate }}
      </span>
    </a>
  </div>

  <div class="menu-items mt-auto" *ngIf="isAuthorized">
    <a
      cf-link
      size="sm"
      color="grey-80"
      routerLink="/availability"
      routerLinkActive="active"
      [queryParamsHandling]="getQueryParamsHandling('/availability')"
      (click)="closeSidenav()"
    >
      <cf-icon icon="clipboard" color="grey-80"></cf-icon>
      <span class="item">
        {{ 'schedules.booking_schedules' | translate }}
      </span>
    </a>
    <!--    <a-->
    <!--      cf-link-->
    <!--      size="sm"-->
    <!--      color="grey-80"-->
    <!--      class="mt-3"-->
    <!--      routerLink="/dashboard"-->
    <!--      (click)="closeSidenav()"-->
    <!--    >-->
    <!--      <cf-icon icon="group_people" color="grey-80"></cf-icon>-->
    <!--      <span class="item">-->
    <!--        {{ 'My Connections' }}-->
    <!--      </span>-->
    <!--    </a>-->

    <div class="separator">
      <cf-text size="xs" color="raven-20"> Integrations </cf-text>
      <hr />
    </div>

    <a
      cf-link
      size="sm"
      color="grey-80"
      routerLink="/connected-calendar"
      routerLinkActive="active"
      [queryParamsHandling]="getQueryParamsHandling('/connected-calendar')"
      (click)="closeSidenav()"
    >
      <cf-icon icon="calendar_blank" color="grey-80"></cf-icon>
      <span class="item">{{ 'calendars.title' | translate }}</span>
    </a>
    <a
      cf-link
      size="sm"
      color="grey-80"
      class="mt-3"
      routerLink="/settings/meeting-tools"
      routerLinkActive="active"
      [queryParamsHandling]="getQueryParamsHandling('/settings/meeting-tools')"
      (click)="closeSidenav()"
    >
      <cf-icon icon="grid" color="grey-80"></cf-icon>
      <span class="item">{{ 'header.meeting_tools' | translate }}</span>
    </a>

    <!--    <hr />-->

    <!--    <cf-text size="sm" weight="500" class="ms-4"> En </cf-text>-->
  </div>
</div>
