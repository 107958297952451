import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { LoadingService } from '@cf/core';
import { IUserNotice } from '@cf/temp/services';

@Component({
  selector: 'cf-user-notice',
  template: `
    @if (notice) {
      <div [innerHTML]="notice.message"></div>
      <cf-icon
        icon="close"
        color="white"
        class="pointer ms-4"
        cfDebounceClick
        (debounceClick)="emitDismiss.emit()"
        [class.disabled]="loadingService.loadingState$ | async"
      ></cf-icon>
    }
  `,
  styleUrl: './user-notice.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UserNoticeComponent {
  @Input({ required: true }) notice!: IUserNotice;

  @Output() emitDismiss = new EventEmitter();

  constructor(public readonly loadingService: LoadingService) {}
}
